import React from "react";
import hand from "../assets/hand.svg";
import mapBackground from "../assets/mapbackground.svg";

const Technology = () => {
  return (
    <div className="md:h-[210vh] h-auto bg-techBackground md:px-[94px] md:py-[75px] py-8 px-2 relative flex flex-col items-center w-full 3xl:max-h-[150vh]">
      {/* <div className="absolute top-[50%] left-0  h-1/2  z-[1]"> */}

      {/* </div> */}

      <div className="md:w-[934px] w-full mb-[80px]">
        <div className="text-black md:text-[40px] text-2xl leading-[120%] mb-5 font-semibold text-center">
          State-Of-The-Art Technology to monitor our vehicles and track their
          movements in real-time
        </div>
        <div className="text-black text-[18px] text-center">
          Our drivers undergo extensive background checks and training to make
          sure they are up to our high standards. We also use state-of-the-art
          technology to monitor our vehicles and track their movements in
          real-time.
        </div>
      </div>
      <img
        src={mapBackground}
        alt=""
        className="w-full  object-cover absolute top-[50%] left-0  h-1/2  z-0"
      />
      <div className="mx-0 w-full md:h-[595px] relative z-10 3xl:max-w-[1000px]">
        <img src={hand} alt="" className="w-full h-full object-cover " />
      </div>
      <div className="flex flex-col items-center justify-center mt-[82px] gap-5 md:w-[934px] w-full ">
        <div className="text-white md:text-[40px] text-2xl font-semibold text-center z-10 leading-[120%] mb-5 ">
          Book your ride today and experience the safety and peace of mind that
          only our service can provide.
        </div>
        <div className="text-white text-[18px] text-center z-10">
          We look forward to serving you!
        </div>
        <button className="text-primary bg-tertiary py-2 px-5 rounded-[10px] text-base z-10">
          Sign up on Cue
        </button>
      </div>
    </div>
  );
};

export default Technology;
