import React from "react";
import Hero from "../components/Hero";
import Objective from "../components/Objective";
import Technology from "../components/Technology";
import Topnav from "../components/Topnav";
import Footer from "../components/Footer";
const Homepage = () => {
  return (
    <div>
      {" "}
      <Topnav />
      <Hero />
      <Objective />
      <Technology />
      <Footer />
    </div>
  );
};

export default Homepage;
