import React from "react";
import heroImage from "../assets/hero.svg";
import android from "../assets/downloadgoogle.svg";
import apple from "../assets/downloadapple.svg";

const Hero = () => {
  return (
    <div className="bg-heroBackground flex items-center justify-center relative md:h-[calc(100vh-84px)] h-auto w-screen overflow-hidden 3xl:max-h-[70vh]  ">
      <div className="w-screen absolute top-0 left-0 -z-10">
        <image
          src={heroImage}
          alt="hero image"
          className="w-full h-full object-cover "
        />
      </div>

      <div className=" z-10  h-full w-screen flex md:items-center md:px-[94px] py-10 px-2 md:py-0 3xl:max-w-[1440px]">
        <div className="flex flex-col md:w-[688px] w-full gap-10">
          <div className="md:text-[60px] text-[36px] leading-[120%] text-white">
            Welcome to the best ride hailing service focused on safety!
          </div>
          <div className="text-[20px] leading-[150%] text-white">
            Download the Cue app
          </div>
          <div className="flex gap-4 flex-col md:flex-row">
            <div className="">
              <img src={android} alt="android" className="full h-full object-cover" />
            </div>
            <div>
              <img src={apple} alt="apple" className="full h-full object-cover" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
