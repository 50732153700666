import background from "../src/assets/checks.svg";
import state from "../src/assets/state.svg";
import commitment from "../src/assets/commitment.svg";
import instagram from "../src/assets/instagram.svg";
import facebook from "../src/assets/facebook.svg";
import linkedin from "../src/assets/Vectorlinkedin.svg";
import twitter from "../src/assets/Vectortwitter.svg";

export const TopnavItems = [
  { id: 1, name: "About Us" },
  {
    id: 2,
    name: "Partner",
    children: [
      {
        id: 1,
        name: "Driver",
        info: "Earn extra money",
      },
      {
        id: 2,
        name: "Courier",
        info: "Get paid for delivery",
      },
      {
        id: 3,
        name: "Fleet",
        info: "Earn more by adding your fleet to Cue",
      },
    ],
    open: "first",
  },
  {
    id: 3,
    name: "Services",
    children: [
      {
        id: 1,
        name: "Ride",
        info: "Request a ride in minutes",
      },
      {
        id: 2,
        name: "Airplane",
        info: "Coming soon",
      },
      {
        id: 3,
        name: "Boat",
        info: "Coming soon",
      },
    ],
    open: "second",
  },
];

export const footerItems = [
  {
    id: 1,
    name: "Service",
    children: [
      {
        id: 1,
        name: "Ride",
      },
      {
        id: 2,
        name: "Airport",
      },
      {
        id: 3,
        name: "Cities",
      },
    ],
  },
  {
    id: 1,
    name: "Partner with Cue",
    children: [
      {
        id: 1,
        name: "Sign up as Driver",
      },
      {
        id: 2,
        name: "Sign up as Rider",
      },
      {
        id: 3,
        name: "Fleets",
      },
    ],
  },
  {
    id: 1,
    name: "Company",
    children: [
      {
        id: 1,
        name: "About Us",
      },
      {
        id: 2,
        name: "Blog",
      },
      {
        id: 3,
        name: "Careers",
      },
    ],
  },
];
export const objectivesItems = [
  {
    id: 1,
    name: "Extensive Background Checks",
    text: "Our drivers undergo extensive background checks and training to make sure they are up to our high standards.",
    img: background,
  },
  {
    id: 2,
    name: "State-Of-The-Art Technology",
    text: "We also use state-of-the-art technology to monitor our vehicles and track their movements in real-time.",
    img: state,
  },
  {
    id: 3,
    name: "Commitment To Safety",
    text: "Our commitment to safety, we also offer affordable rates and convenient pickup and drop-off services.",
    img: commitment,
  },
];
export const footer = [
  {
    id: 1,
    name: "Driver Privacy Policy",
    route: "driver-privacy",
  },
  { id: 2, name: "Rider Privacy Policy", route: "rider-privacy" },
  {
    id: 3,
    name: "Terms of Use",
    route: "terms",
  },
  {
    id: 4,
    name: "Cookies Setting",
    route: "cookies",
  },
];
export const socials = [
  {
    id: 1,
    img: facebook,
  },
  {
    id: 2,
    img: instagram,
  },
  {
    id: 3,
    img: twitter,
  },
  {
    id: 4,
    img: linkedin,
  },
];
export const policies = [
  {
    title: "Using the Cue app",
    list: [
      "Cue provides an information society service through Cue app that enables mediation of the requests for transport services between the passengers and drivers and Cue, except where expressly stated, does not provide transport services. Transport services are provided by drivers under a contract (with you) for the carriage of passengers. Drivers provide transport services on an independent basis (either in person or via a company) as economic and professional service providers. Cue is not responsible in any way for the fulfilment of the contract entered into between the passenger (you) and the driver. Disputes arising from consumer rights, legal obligations or from law applicable to the provision of transport services will be resolved between the passengers and drivers. Data regarding the drivers and their transport service is available in the Cue app and receipts for journeys are sent to the email address listed in passenger’s profile. ",
      "The passenger (you) enters into a contract with the driver for the provision of transport services via the Cue app. Depending on the payment options supported for given location of the journey, you can choose whether to pay the driver for the transport service in cash or use Cue in-app Payment. Payments for Cue Corporate rides are handled by a separate agreement for Business journeys. Charges will be inclusive of applicable taxes where required by law. Charges may include other applicable fees, tolls, and/or surcharges including a booking fee, municipal tolls, airport surcharges or processing fees for split payments. If you wish, you may also choose to pay a Tip to the driver directly or via the use of Cue in-App Payment. We may limit the maximum value of a Tip at our preference.",
      "During the installation of Cue app, passenger’s mobile number is linked to the respective Cue user account and added to our database. If you are no longer using your mobile number, you must notify Cue within 7 days so we can anonymize your account data. If you do not notify us about any change to your number, your mobile operator may issue the same mobile number to a new person who when using the Cue app then may have access to your data.",
    ],
  },
  {
    title: "Promotional Codes ",
    list: [
      "Cue may send you promotional codes on a per promotion basis. Promotional code credit can be applied towards payment on completion of a ride or other features or benefits related to the service and/or a Third Party’s service and are subject any additional terms that are established on a per promotional code basis. Expiration dates of promo codes will be reflected in-app once you have applied the promo code to your account. ",
      "If your trip amount exceeds the redeemable credit allocated to your ride, the balance will be automatically deducted from your accounts payment method. Similarly, a promotional code credit only applies on a per ride basis and cannot carry over to a next ride/ trip and therefore will be forfeited. Only one promotional code may be applied per trip.",
      "Cue reserves the right to cancel any promotional code at any time for any reason. This includes, but is not limited to, if Cue deems that codes are being used in an unlawful or fraudulent manner, those issued mistakenly, and those which have expired.",

      "",
    ],
  },
  {
    title: "Cue in-App Payment",
    list: [
      "Depending on the payment options supported for the given location of the journey, You can pay for the transport services with a card, mobile carrier billing or other payment methods (e.g: Cue Corporate) as and when available through Cue App. By providing Cue in-App Payment service, Cue acts as commercial agent for the providers of the transport services. Every driver has authorised Cue as their commercial agent for the mediation of conclusion of contracts between the driver and the passenger, including the power to accept payments from the passengers and to forward the payments to the driver. Your obligation to the provider of the transport service will be fulfilled when the payment order is given to transfer funds to DigitPays’ bank account. You, as a passenger are responsible for ensuring that the payment takes place and ensuring that sufficient funds are available.",
      "You may choose to pay a Tip to the driver using the Cue In-app Payment service. The Tp can be paid via the In-app Payment by means authorised by Cue for that purpose. Cue will not hold a commission for the brokerage of the Tip and the Tip will be transferred to the driver in full amount, excluding any taxes, if applicable. Cue reserves the right to withhold the Tip, if the payment of the Tip is suspected as being fraudulent, illegal, for a purpose other than as a gratuity related to the service provided or used in conflict with DigitPay’s Terms and Conditions",
      "When making payments by Cue in-App Payment, Cue receives your payments and forwards money to the driver. Cue may ask additional data from you to verify payment method.",

      "When making payments by Cue in-App Payment for transport services, Cue is not responsible for possible third-party payment costs (e.g mobile operators, bank fees). These service providers may charge you additional fees when processing payments in connection with the Cue in-App Payment. Cue is not responsible for any such fees and disclaims all liability in this regard. Your payment method may also be subject to additional terms and conditions imposed by the applicable third-party payment service provider; please review these terms and conditions before using your payment method.",
      "Cue will be responsible for the functioning of Cue in-App Payment and provide support in resolving problems. The resolution of disputes related to Cue in-App Payment also takes place through us. For payment support service please contact: info@cue.cab. Inquiries submitted by e-mail or Cue App will receive a response within one business day. Cue will resolve Cue in-App Payment related complaints and applications within two business days. ",
      "Upfront Fare. ​You may be offered to use a ride option that allows you to agree to a fixed Fare for a given instance of Transportation service provided by the Driver (i.e Upfront Fare). Upfront Fare is communicated to you via the Cue App before the ride is requested. Upfront Fare shall not be applied if you change the destination during the ride, the ride takes materially longer than estimated due to traffic or other factors, or when other unexpected circumstances impact the characteristics of the ride materially (e.g a route is used where tolls apply)",
    ],
  },
  {
    title: "Promotional Codes ",
    list: [
      "If you order a transport service and the driver has agreed to undertake the work then the transport service is considered to be ordered.  ",
      "Once a driver confirms that he/she will complete your journey, you will enter into a separate agreement with the driver for the provision of the journey on such terms and conditions as you agree with the driver. Cue does not provide journeys and is not a party to your agreement with the relevant driver.",
      "Cancelling the use of an ordered transport service is considered to be the situation where the driver has replied to your request and you subsequently reject, cancel or refuse the transport service. When a transport service request is cancelled after certain time period you are required to pay a cancellation fee.",
      "If you cancel a transport service request on multiple successive instances within 24-hour we may temporarily block your account for warning. After multiple such warnings, we may suspend your account for longer period (e.g 6 months). After that period you could ask to reactivate your account and your application will be reviewed by DigitPay.",
      "When driver notifies the passenger about the arrival of the vehicle to its destination and passenger or people for whom the transport was ordered do not arrive at the vehicle within certain time period as specified in the Cue app, the request will be deemed cancelled. Sometimes driver may decide to cancel your request, please note that Cue is not responsible for such situations.",
      " Once the driver arrives and sends you a notification that he/she has arrived the Cue app may begin charging fare on a waiting time basis according to the rates specified in the Cue app. ",
      "If you have requested transport services using the Cue app and cause damage to the driver’s vehicle or its furnishing (among else, by blemishing or staining the vehicle or causing the vehicle to stink), the driver will have the right to require you to pay a penalty of 50 EUR and require compensation for any damages exceeding the penalty. If you do not pay the penalty and/or compensate the damage, Cue may pursue the claims on behalf of the provider of the transport service.",
    ],
  },
  {
    title: "License to use Cue app ",
    list: [
      "As long as you comply with these General Terms and Conditions, we agree to grant you a royalty free, revocable, non-exclusive, right to access and use the Cue app in accordance with these General Terms and Conditions, the Privacy Notice and the applicable app-store terms. You may not transfer or sub-license this right to use the Cue app. In the event that your right to use Cue app is cancelled, the corresponding non-exclusive license will also be cancelled.",
    ],
  },
  {
    title: "Liability ",
    list: [
      "As the Cue app is an information society service (a means of communication) between passengers and drivers, we cannot guarantee or take any responsibility for the quality or the absence of defects in the provision of transport services. As the usage of Cue app for requesting transport services depends on the behaviour of the drivers, Cue does not guarantee that you will always have offers available for the provision of the transport services.",
      "The Cue app does not offer or broker transport services for passengers. It is also not an transport agency service for finding passengers for transport providers. The Cue app is used as the means for organising the provision of transport services.",
      "The consumer’s right of refund is not applied to Cue app orders. Requesting a refund from the transport service does not withdraw you from the agreement in the course of which the provision of the transport service was ordered. ",
      "The Cue app is provided on an 'as is' and 'as available' basis. Cue does not represent, warrant or guarantee that access to Cue app will be uninterrupted or error free. In case of any faults in the software, we will endeavour to correct them as soon as possible, but please keep in mind that the functioning of the app may be restricted due to occasional technical errors and we are not able to guarantee that the app will function at all times, for example a public emergency may result in a service interruption.",
      "DigitPay, its representatives, directors and employees are not liable for any loss or damage that you may incur as a result of using Cue app or relying on, the journey contracted for through the Cue app, including but not limited to:",
      "any direct or indirect property damage or monetary loss;",
      "loss of profit;",
      "loss of business, contracts, contacts, goodwill, reputation and any loss that may arise from interruption of the business;",
      "loss or inaccuracy of data; and",
      " any other type of loss or damage.",
      "The financial liability of Cue in connection with breach of the contract will be limited to 500 euros. You will have the right to claim for damages only if Cue has deliberately violated the contract. Cue will not be liable for the actions or inactions of the driver and will not be liable for damages that the driver causes to the passengers",
      " You agree to fully indemnify and hold DigitPay, their affiliate companies, representatives, employees and directors harmless from any claims or losses (including liabilities, damages, costs and expenses of any nature) that they suffer as a result of your use of the Cue app (including the journeys you obtain through your use of the Cue app). ",
      "Cue may immediately end your use of the Cue app if you breach these General Terms and Conditions or we consider it necessary to protect the integrity of Cue or the safety of drivers",
    ],
  },
  {
    title: "Good practice using the Cue app",
    list: [
      "As Cue is not a provider or broker of the transport services, any issues with defects or quality of the transport services will be resolved in accordance with the rules and regulations of the transport service provider or the relevant public authority.",
      "We ask to fill out a feedback form in the Cue app. This enables us to offer suggestions to the drivers for improving the quality of their service.",
      "We expect that you use Cue app in good faith and be respectful of the drivers who offer their services through Cue app. Cue retains the right to close your account if you have violated the terms set out in this General Terms and Conditions or if your activities are malicious, i.e. withholding payment for the provision of the transport service, fraud, being disrespectful towards the drivers, etc. In these cases, your Cue app account may be revoked without prior notice.",
      "Cue will make every effort to ensure that only drivers, who have integrity and are respectful of their profession and passengers, use the Cue app. However, we are in no position to guarantee that every provider of transport services, located by the Cue app, satisfies the aforementioned criteria at all times. If you experience objectionable transport service, please notify the company responsible for the service, a supervisory authority or our customer support.",
      "",
    ],
  },
  {
    title: "Amendments to the General Terms and Conditions",
    list: [
      "If any substantial amendments are made to the General Terms and Conditions, then you will be notified by e-mail or Cue app notifications. If you continue using Cue app, you will be deemed to accept the amendments.",
    ],
  },
  {
    title: "Final Provisions",
    list: [
      "The General Terms and Conditions will be governed by and construed and enforced in accordance with the laws of the Federal Republic of Nigeria. If any provision of the General Terms is held to be unenforceable, the parties will substitute for the affected provision an enforceable provision that approximates the intent and economic effect of the affected provision. ",
    ],
  },
];
export const firstPolicies = [
  {
    title: "DEFINITIONS",
    list: [
      "Digitpay (also referred to as “we”, “our” or “us”) – Cue Operations OÜ a private limited company incorporated and registered under the laws of Federal Republic of Nigeria with registration code 1571814, registered office 11, Ethiope Close, Abuja, Federal Republic of Nigeria, or other DigitPay group company or co-operation partner where DigitPay Services are not provided by DIgitPay. A list of DigitPay group companies and partners is available  https://digitpay.net/group",
      "Cue Services – services that Cue provides, including provision and maintenance of Cue App, Cue Platform, In-app Payment, customer support, communication between the Driver and the Passenger and other similar services.",
      "Cue App – a smartphone application for Drivers and Passengers to request and receive Transportation Services",

      "Cue Platform – technology connecting Passengers with Drivers to help them move around cities more efficiently",
      "Passenger – a person requesting Transportation Services by using Cue Platform.",
      "Driver (also referred to as „you“) – the person providing Transportation Services via the Cue Platform. Each Driver will get a personal Cue Driver Account to use Cue App and Cue Platform.",
      {
        text: "Agreement – this agreement between Driver and Cue regarding the use of Cue Services which consists of:",
        list: [
          "these General Terms;",
          "special terms displayed in Cue App, e.g regarding price info or service descriptions;",
          "he Drivers guidelines; and",
          "other terms referred to in this Agreement as may be amended from time to time.",
        ],
      },
      "Fare – the fee a Passenger is obliged to pay Driver for provision of the Transportation Services.",
      "Cue Fee – the fee that Driver is obliged to pay to Cue for using the Cue Platform.",
      "In-app Payment – cards, carrier billing and other payment methods used by the Passenger via the Cue App to pay for the Transportation Services",
      {
        firstText:
          "In-app Payment – cards, carrier billing and other payment methods used by the Passenger via the Cue App to pay for the Transportation Service",
        link: "https://drivers.cue.cab ",
        after: "by entering username and password",
      },
      "Tip-a gratuity offered by the Passenger at their sole discretion in addition to the Fare paid.",
      "Transportation Services – transport service a Driver is providing to Passenger whose request Driver has accepted through the Cue App.",
    ],
  },
  {
    title: "ENTRY INTO THE AGREEMENT",
    list: [
      {
        text: 'Prior to using the Cue Services, you must sign up by providing the requested information in the signup application on website and uploading necessary documentation as required by us. You may sign up either as a legal or a natural person. Upon successful completion of the signup application, we will provide you with a personal account accessible via a username and password. By clicking the „Sign up" button located at the end of the signup application, you represent and warrant that: ',
        list: [
          "pursuant to valid legal acts, you are entitled to enter into an agreement with us to use the Cue Platform for providing the Transportation Service; ",
          "you have carefully studied, fully understand and agree to be bound by these General Terms, including all obligations that arise as provided herein and from Agreement; ",
          "all the information you have presented to us is accurate, correct and complete; ",
          "you will keep Cue Driver Account accurate and profile information updated at all times; ",
          "you will not authorize other persons to use your Cue Driver Account nor transfer or assign it to any other person; ",
          "you will not use the Cue Services for unauthorized or unlawful purposes and impair the proper operation of the Cue Services;  ",
        ],
      },
      "at all times, you fully comply with all laws and regulations applicable in the state you are providing Transportation Services in, including (but not limited to) laws regulating passenger transportation services; ",
      "You are obliged to provide your bank requisites in course of filling the payment details upon registration. In case you are a legal person, you must insert the bank account of the company. We are transferring In-app Payment fees to the bank account that you have provided. We are not liable for any incorrect money transactions in case you have provided wrong bank requisites. ",
      "After submitting the signup application, you will receive an e-mail with additional conditions that must be met in order to use Cue Services. These conditions may include providing criminal records, valid driving license, satisfactory technical state of the vehicle, completion of a training course, owning a GPS-supporting mobile device and other conditions as described in the pertinent e-mail. The failure to comply with the provided requirements and conditions may result in termination of the Agreement and right to use the Cue Services",
      "You agree that in specific cities or countries Cue Operations OÜ may assign any of our obligations arising from the General Terms or Agreement to Cue group companies and partners. This includes, among else, assigning the rights and obligations regarding reviewing documents related to signup applications, trainings, collection of Cue Fees, forwarding you the fees due, mediating In-app Payment, licensing the Cue App, etc.  ",
      "Registering the account as a legal person (i.e. a company). You are considered to be a legal person, if the recipient of the fees is marked as a legal person in payment details (as accessible in the Cue Driver Account). In such case, the indicated legal person is considered to be the provider of Transportation Services and a party to these General Terms, Agreement and any further agreements. Only the specific natural person indicated in the signup process may factually provide the Transportation Services. Such natural person may use the account of the Driver only if he/she has read and agrees to be bound by these General Terms and any further documentation that is part of the Agreement. THE LEGAL PERSON IN THE PAYMENT DETAILS AND THE NATURAL PERSON FACTUALLY PROVIDING THE TRANSPORTATION SERVICES UNDER CUE ACCOUNT SHALL REMAIN JOINTLY AND SEVERALLY LIABLE FOR ANY INFRINGEMENT OF THE GENERAL TERMS AND AGREEMENT CONDUCTED BY THE DRIVER.",
      "Registering Cue Driver Account as a fleet company. Upon concluding a separate agreement, a fleet company may itself register accounts to its employees and/or service providers. In such case the fleet company shall be required to ensure that its employees and/or service providers conform to the requirements of General Terms, Agreement and any further agreements and agrees to act in accordance and be bound with its conditions and obligations. The fleet company and its employees and/or service providers shall remain jointly and severally liable for any infringement conducted by such employee and/or service provider. ",
    ],
  },
  {
    title: "RIGHT TO USE Cue APP AND Cue DRIVER ACCOUNT ",
    list: [
      "License to use the Cue App and the Cue Driver Account. Subject to your compliance with the Agreement, We hereby grant you have a license to use the Cue App and the Cue Driver Account. The license does not grant you the right to sublicense or transfer any rights to the third persons. Regardless of the above and if so agreed separately, fleet companies may sub-license the Cue App and the Cue Driver Account to the members of its fleet. ",
      {
        text: 'Prior to using the Cue Services, you must sign up by providing the requested information in the signup application on website and uploading necessary documentation as required by us. You may sign up either as a legal or a natural person. Upon successful completion of the signup application, we will provide you with a personal account accessible via a username and password. By clicking the „Sign up" button located at the end of the signup application, you represent and warrant that: ',
        list: [
          "In course of using the Cue App and/or Cue Driver Account you may not: ",
          "decompile, reverse engineer, or otherwise attempt to obtain the source code of the Cue App, the Cue Driver Account or other software of Cue;  ",
          "modify the Cue App or the Cue Driver Account in any manner or form or to use modified versions of the Cue App or Cue Driver Account; ",
          "transmit files that contain viruses, corrupted files, or any other programs that may damage or adversely affect the operations on Cue Platform;",
          "attempt to gain unauthorized access to the Cue App, Cue Driver Account or any other Cue Services.",
        ],
      },
      "The License granted herein revokes automatically and simultaneously with termination of the Agreement. After termination of the Agreement you must immediately stop using the Cue App and the Cue Driver Account and we are entitled to block and delete Driver account without a prior notice.",

      "Using tags and labels of Cue. Additionally, we may give you tags, labels, stickers or other signs that refer to Cue brand or otherwise indicate you are using the Cue Platform. We grant you a non-exclusive, non-sublicensable, non-transferable license to use such signs and only for the purpose of indicating you are providing Transportation Services via the Cue Platform. After termination of the Agreement you must immediately remove and discard any signs that refer to Cue brand.",
      "All copyrights and trademarks, including source code, databases, logos and visual designs are owned by Cue and protected by copyright, trademark and/or trade secret laws and international treaty provisions. By using the Cue Platform or any other Cue Services you do not acquire any rights of ownership to any intellectual property.",
    ],
  },
  {
    title: "PROVIDING THE TRANSPORTATION SERVICES",
    list: [
      "The Driver’s Obligations. You hereby guarantee to provide Transportation Services in accordance with the General Terms, Agreement as well as laws and regulations applicable in the state where you are providing Transportation Services. Please note that you are fully liable for any violation of any local laws and regulations as may arise from providing Transportation Services. ",
      "You must have all licenses (including a valid driver’s license), permits, car insurance, liability insurance (if applicable), registrations, certifications and other documentation that are required in the applicable jurisdiction for providing the Transportation Services. It is your obligation to maintain the validity of all aforementioned documentation. Cue reserves the right to require you to present evidence and submit for review all the necessary licenses, permits, approvals, authority, registrations and certifications.",
      "You must provide the Transportation Services in a professional manner in accordance with the business ethics applicable to providing such services and endeavour to perform the Passenger's request in the best interest of the Passenger. Among else, you (i) must take the route least costly for the Passenger, unless the Passenger explicitly requests otherwise; (ii) may not make any unauthorised stops; (iii) may not have any other passengers in the vehicle other than the Passenger and the passengers accompanying the Passenger; and (iv) must adhere to any applicable traffic acts and regulations, i.e must not conduct any actions that may disrupt driving or the perception of traffic conditions, including holding a phone in his/her hand while the vehicle is moving.",
      "You retain the sole right to determine when you are providing the Transportation Services. You shall accept, decline or ignore Transportation Services requests made by Passengers at your own choosing.",
      "Costs you incur while providing the Transportation Services. You are obliged to provide and maintain all equipment and means that are necessary to perform the Transportation Services at your own expense, including a car, smartphone, etc. You are also responsible for paying all costs you incur in the course of performing the Transportation Services including, but not limited to, fuel, mobile data plan costs, duty fees, amortization of the vehicle, insurance, relevant corporate or payroll taxes etc. Please bear in mind that using the Cue App may bring about consummation of large amount of data on your mobile data plan. Thus, we suggest you to subscribe for a data plan with unlimited or very high data usage capacity. ",
      "Fares. You are entitled to charge a fare for each instance you have accepted a Passenger on the Cue Platform and completed the Transportation Service as requested (i.e. Fare). The Fare is calculated based on a default base fare, the distance of the specific journey as determined by the GPS-based device and the duration of the specific travel. The default base fare may fluctuate based on the local market situation. In markets with In-app payment, you may negotiate the Fare by sending us a pertinent request that has been either signed digitally or by hand. Additionally, you shall always have the right to charge the Passenger less than the Fare indicated by the Cue App. However, charging the Passenger less than the Cue App indicates, does not decrease the Cue Fee.",
      " Upfront Fare. ​A Passenger may be offered to use a ride option that allows the Passenger to agree to a fixed Fare for a given instance of Transportation Service provided by you (i.e Upfront Fare). Upfront Fare is communicated via the Cue App to a Passenger before the ride is requested, and to you when the ride is accepted or at the end of the ride. The Fare calculated in accordance with section 4.6 shall be applied instead of Upfront Fare if the Passenger changes the destination during the ride, the ride takes materially longer than estimated due to traffic or other factors, or when other unexpected circumstances impact the characteristics of the ride materially (e.g a route is used where tolls apply).",
      "In markets with In-app payment, if you find that there has been an error in the calculation of the Fare and wish to make corrections in the calculation of the Fare, you must submit a petition in the section „Fare Review“ of the Cue App. If a petition in the section „Fare Review“ of the Cue App has not been submitted, then Cue shall not recalculate the Fare or reimburse you for an error made in the calculation of the Fare. This option is not applicable in markets with only cash payment.",
      "Cue may adjust the Fare for a particular order completed, if we detect a violation (such as taking a longer route or not stopping the fare meter of the Cue App after the Transportation Services have been completed) or in case a technical error affecting the final fare is identified. Cue may also reduce or cancel the fare in case we have reasonable cause to suspect a fraud or a complaint by the Passenger indicates a violation by you. Cue will only exercise its right to reduce or cancel the fare in a reasonable and justified manner.",
      "Passenger may have the option to pay the fare for the Transportation Services either directly to you or via the In-app Payment, in markets where this option is available, as described in section 6 of these General Terms. In case the Passenger pays the Fare directly, it is your obligation to collect the Fare. In case the Passenger fails or refuses to pay, Cue will send a notice of debt to the Passenger on behalf of you. Such authorisation derives from the mandate of paying agent given to Cue and does not entail that Cue has an obligation to compensate the Fare not paid by the Passenger. If the passengers in the vehicle do not agree to pay the Fare for the provision of Transportation Service, the Fare will be paid by the Passenger who has ordered the provision of Transportation Service. If Passenger justifiably refuses to pay the Fare on the account that your information stated in the Cue App is incorrect, then Cue will not reimburse you for such expenses. ",
      " Tips. In some markets Passenger may be given the option to tip you after a successful provision of Transportation Services. The Passenger can Tip you only by means authorised by Cue for Tipping. The Tip will not affect the amount of Cue Fees and Cue will not collect a commission on the Tip paid by the Passenger. You are obliged to fully comply with any tax obligations arising from the Tipping. We may limit the maximum value of a Tip at our sole discretion.",
      "Receipts. After each successful provision of Transportation Services, Cue shall create and forward a receipt to the Passenger consisting of some or all of the following information: the company’s business name, place of business, the first name and surname of the Driver, a photo of the Driver, service license number (if applicable), the registration number of the vehicle, the date-, the time-, the start and end locations-, the duration and length-, the Fare and the Fare and Tip paid for the provision of the Transportation Services. The receipt of each provision of Transportation Services is available to you via the Cue Driver Account.",
      "Cancellation fee & wait time fee. Passenger may cancel a request for Transportation Services that a Driver has accepted via the Cue App. In some markets, Driver may be entitled to the Fare for cancelled Transportation Services (Cancellation Fee) in the event that a Passenger cancels accepted request for Transportation Services after certain time period determined by Cue App.",
      "If, in the course of the provision of the Transportation Services, a Passenger or its co-passengers negligently damage the vehicle or its furnishing (among else, by blemishing or staining the vehicle or causing the vehicle to stink), you shall have the right to request the Passenger to pay a penalty up to 50 EUR and request compensation for any damages exceeding the penalty. If the Passenger does not consent to paying the penalty and/or compensating the damage, you must notify us and we will then try to collect penalty and/or relevant costs on the your behalf from the Passenger. However, bear in mind that we are not taking any liability for direct or indirect damages in relation to cleaning or maintenance of the vehicle caused by Passenger.",
      "Your tax obligations. You hereby acknowledges that you are obliged to fully comply with all tax obligations that arise to you from the applicable laws in relation to providing the Transportation Services, including (i) paying income tax, social security tax or any other tax applicable; and (ii) fulfilling all employee and tax registration obligations for calculations in regard to accounting and transfers to applicable State authorities as required by the applicable law. In case the Tax authority will submit a valid application to us to provide information regarding the activities of you, we may make available to the Tax authority the information regarding the activities of you to the extent set forth in valid legal acts. Additionally, it is your obligation to adhere to all applicable tax regulations that may apply in connection with the provision of Transportation Services. You hereby agree to compensate Cue all state fees, claims, payments, fines or other tax obligations that Cue will incur in connection with the obligations arising from applicable tax regulations not having been met by you (including paying the income tax and social tax). ",
      "The Driver’s authorisation to issue invoices. Cue has a right to issue an invoice on your behalf to the Passenger in order to compensate you any Fares, contractual penalties or other fees that Cue mediates to you. In markets where Cue issues invoices, the invoice will be made available to you via the Cue Driver Account. ",
    ],
  },
  {
    title: "CUE FEES",
    list: [
      "As long asIn order to use the Cue Services, you are obliged to pay to a fee (i.e. the Cue Fee). The Cue Fee is paid based on the Fare of each Transportation Service order that you have completed. The amount of the Cue Fee is made available to you via e-mail, Cue App, Cue Driver Account or other pertinent means. Please acknowledge that the Cue Fee may change from time to time. We shall send you a prior notification of each such change.",
      "You must pay the Cue Fee and any other fees due to us for the previous month at latest by the 15th date of the following month. Upon delay with payment of the Cue Fee, you shall be obliged to pay a penalty of late payment in the amount of 0,04% (zero point zero four percent) of the unpaid amount per day. You are obliged to cover all costs incurred by us, which are related to debt collection activities.",
    ],
  },
  {
    title: "IN-APP PAYMENTS ",
    list: [
      "We may enable Passengers to pay for the Transportation Service via cards, carrier billing and other payment methods (Cue Corporate etc) directly in the Cue App (i.e. In-app Payment). You hereby authorise us as your commercial agent to receive the Fares or other fees paid by the Passenger via In-app Payment and to forward relevant funds to you. Any payment obligation made by the Passenger via the In-app Payment shall be considered fulfilled as of the time that the payment has been made. ",
      "You may not refuse payment by the Passenger via the In-app Payment, or influence the Passenger against the use of the In-app Payment. In case you refuse to accept an In-app Payment without just cause, we shall be entitled to charge you a contractual penalty in the amount of 15 Euros for every refusal and/or block your right to use the Cue Services in case of repetitive behaviour.",
      " Cue reserves the right to distribute promo code to riders at our discretion on a per promotional basis. You are required to accept the use of promo code only when the rider applies the code in-app to a trip using card payment. Promo codes may not be applied to cash paid trips. If the use of promo codes is suspected as being fraudulent, illegal, used by a Driver in conflict with our Terms and Conditions relating to promo code use, then the promo code may be canceled and the outstanding amount will not be reimbursed by Cue to the Driver.",
      "If the option is available and the Passenger chooses to Tip you directly in the Cue App, the Tip will be collected by Cue on your behalf together with the Fares and other fees paid by the Passenger via the In-app Payment. If the payment of the Tip is suspected as being fraudulent, illegal, for a purpose other than as a gratuity related to the service provided or used by a Driver in conflict with our Terms and Conditions and, then the Tip can be withheld by Cue.",
      "You are entitled to review In-app Payment reports in the Cue Driver Account or App. The reports will show the amounts of the In-app Payments brokered in the previous week as well as the withheld amounts of the Cue Fee. You must notify us of any important circumstances which may affect our obligations to collect and distribute the Fares paid via In-app Payment.",
      "We are not obliged to pay you the Fare due from the Passenger if the In-app Payment failed because Passenger’s credit card or other payment is cancelled or is unsuccessful for other reasons. In such case we will help you in requesting the Fare due from the Passenger and shall transmit it to you once the Passenger has made the requested payment. ",
      "Before providing Transportation Services, you must verify that the service is being actually provided to the right Passenger or the Passenger has expressly confirmed he/she allows other passengers to ride under Passenger’s account. If you make a mistake in identifying the Passenger, and the In-app Payment is charged to a person, who has not been provided or has not approved the Transportation Services for other passengers, then we shall reimburse the person for the Fare. In such case you are not entitled to receive the Fare from us. Additionally, for every wrongfully applied In-app Payment, we shall be entitled to charge you a contractual penalty up to 10 Euros. ",
      "Please note that we will set off any Fares or Tip paid via In-app Payment against the amounts that you are obliged to pay to us (i.e. Cue Fees and contractual penalties). We reserve the right to fulfil any of your financial liabilities to any Cue group companies, in which case we will acquire the right to submit a claim against you. We may set off any of your financial liabilities against financial liabilities that you may have against us.",
      "If we are not able to pay the Fees or Tip to you due to you not including your bank account details in your Driver´s account or if the bank account details have been noted incorrectly, then we will hold such payments for 180 days. If you do not notify us of the correct bank account details within 180 days from the date that the right to claim such payments has been established, your claim regarding the payment of the Fare or Tip not transferred to you shall expire.",
    ],
  },
  {
    title: "CUSTOMER SUPPORT",
    list: [
      "We provide the Drivers customer support regarding the use of the Cue Services. We have the right to stop providing the customer support services in case you are in delay with any of the payments for more than 5 (five) calendar days. .",
      "In addition to the rating, we measure your level of activity and provide you with an activity score, which is based on your activity regarding accepting, declining, not responding and completing Transportation Service requests.",
      "In order to provide reliable services to Passengers, we may determine a minimum average rating and a minimum activity score that Drivers must establish and maintain. If, after a pertinent notification from us, you do not increase your average rating or activity score to minimum level within the prescribed time period, your Driver´s account will be automatically suspended either temporarily or permanently. We may reverse the suspension of your account if it is merited by any external circumstances or it is detected that the suspension was caused by a system error or false ratings. ",
    ],
  },
  {
    title: "RATINGS AND ACTIVITY",
    list: [
      "Market overviews. We may send you, via the Cue App, Cue Driver Account, SMS, e-mail or other means, market overviews, in order to increase your awareness regarding when the demand by the Passengers is highest. Such market overviews are merely recommendatory and do not constitute any obligations for you. As the market overview estimations are based on previous statistics, we cannot give any guarantees that the actual market situation will correspond to the estimations provided in the market overview.",
      "Campaigns promising minimum income. We may also provide campaigns, whereby we will guarantee a minimum income if you provide Transportation Services within a specified timeframe. If the specified minimum is not reached by you, we shall compensate the gap. The specific requirements and conditions will be sent via the Cue App, Cue Driver Account, SMS, e-mail or other means. We have full discretion in deciding if, when and to which Drivers we provide such campaigns. If we have reasonable cause to suspect any fraudulent activity by you, we may withhold your Fare until the suspicion of fraud has been cleared. ",
      "Campaigns for Passengers. We may also occasionally arrange various campaigns to Passengers in order to promote the Cue Platform. If the Fare paid by the Passengers is reduced as part of such campaign, we shall pay you compensation, amounting to the monetary value of the benefit offered to the Passengers. We may set off the marketing compensation against the Cue Fee.",
    ],
  },
  {
    title: "MARKET OVERVIEWS AND CAMPAIGNS ",
    list: [
      "The General Terms and Conditions will be governed by and construed and enforced in accordance with the laws of the Federal Republic of Nigeria. If any provision of the General Terms is held to be unenforceable, the parties will substitute for the affected provision an enforceable provision that approximates the intent and economic effect of the affected provision. ",
    ],
  },
  {
    title: "RELATIONSHIP BETWEEN YOU, US AND THE PASSENGERS",
    list: [
      "You hereby acknowledge and agree that we provide an information society service and do not provide Transportation Services. By providing the Cue Platform and Cue Services, we act as marketplace connecting Passengers with Drivers to help them move around cities more efficiently. You acknowledge that you are providing the Transportation Services on the basis of a contract for carriage of passengers and that you provide the Transportation Services either independently or via a company as an economic and professional activity. Cue, as the operator of Cue App acts as the commercial agent of the Drivers for the mediation of conclusion of contracts between the Driver and the Passenger, and thus, among other things, accepts payments from the Passengers and forwards the payments to the Driver.",
      "You acknowledge that no employment agreement nor an employment relationship has been or will be established between you and us. You also acknowledge that no joint venture or partnership exists between you and us. You may not act as an employee, agent or representative of us nor bind any contract on behalf of us. If due to the implication of mandatory laws or otherwise, you shall be deemed an employee of us, you hereby agree to waive any claims against us that may arise as a result of such implied employment relationship.",
      "You may not transfer your rights and obligations deriving from the General Terms or Agreement to any third party.",
    ],
  },
  {
    title: "PROCESSING OF PERSONAL DATA, ACCESS TO DATA",
    list: [
      {
        firstText:
          "Your personal data will be processed in accordance with the Privacy Notice, available at",
        link: "https://cue.cab/compliance/drivers-privacy/",
        after: "",
      },
      "Cue has access to all personal data and other data provided or generated in connection with your use of the Cue Services. Cue shall take all reasonable steps to ensure confidentiality of such data and comply with all applicable Privacy Policies and laws whenever such data contains personal data. Except where otherwise provided by applicable Privacy Policies and laws, Cue maintains access to such data also after the Agreement between you and Cue is terminated.",
      "You have access to personal and other data provided by you or generated in connection with your use of the Cue Services to the extent that is made available to you under your Cue Driver Account through Cue App. You shall take all reasonable steps to ensure confidentiality of such data and comply with applicable Privacy Policies and laws as long and to the extent that such data contains personal data of Passengers.",
    ],
  },
  {
    title: "LIABILITY",
    list: [
      "The Cue Platform is provided on an 'as is' and 'as available' basis. We do not represent, warrant or guarantee that access to Cue Platform will be uninterrupted or error free. As the usage of Cue Platform for requesting transportation services depends on the behavior of Passengers, we do not guarantee that your usage of the Cue Platform will result in any Transportation Service requests.",
      {
        text: "To the maximum extent permitted under the applicable law, we, nor Cue’s representatives, directors and employees are not liable for any loss or damage that you may incur as a result of using the Cue Services, including but not limited to: ",
        list: [
          " any direct or indirect property damage or monetary loss; ",
          "loss of profit or anticipated savings;  ",
          "loss of business, contracts, contacts, goodwill, reputation and any loss that may arise from interruption of the business;  ",
          "loss or inaccuracy of data; and  ",
          "any other type of loss or damage",
        ],
      },
      "The financial liability of us in connection with violating the General Terms or Agreement will be limited to 500 euros. You shall have the right to claim for damages only if we have deliberately violated the General Terms or Agreement.",
      "We shall not be liable for the actions or non-actions of the Passenger or co-passengers and shall not be liable for any loss or damage that may incur to you or your vehicle as a result of actions or non-actions of the Passenger or co-passengers.",
      "You shall be fully liable for breach of the General Terms, Agreement or any other applicable laws or regulations and must stop and remedy such breach immediately after receipt of a respective demand from us or any state authority. You shall indemnify us for any direct and/or indirect loss and/or damage, loss of profits, expense, penalty, fine that we may occur in connection with your breach of the General Terms, Agreement and laws and regulations. If Passenger presents any claims against us in connection with your provision of Transportation Services, then you shall compensate such damage to us in full within 7 (seven) days as of your receipt of the respective request from us. In case we are entitled to present any claims against you, then you shall compensate us any legal costs related to evaluation of the damages and submission of claims relating to compensation for such damage.",
    ],
  },
  {
    title: "TERM, SUSPENSION AND TERMINATION",
    list: [
      "The conditions expressly specified in these General Terms shall enter into force as of submitting the signup application. Agreements and other terms shall enter into force once the specific document or message has been made available to you and you commence or continue providing Transportation Services on Cue Platform.",
      "You may terminate the Agreement at any time by notifying Cue at least 7 (seven) days in advance, after which your right to use the Cue Platform and Cue Services shall terminate. Cue may terminate the Agreement at any time and for any reason at the sole discretion of us by notifying you at least 3 (three) days in advance.",
      "Cue is entitled to immediately terminate the Agreement and block your access to the Cue Platform without giving any advance notice in case you breach the General Terms or Agreement, any applicable laws or regulations, disparage Cue, or cause harm to Cue’s brand, reputation or business as determined by Cue in our sole discretion. In the aforementioned cases we may, at own our discretion, prohibit you from registering a new Driver account. ",
      "We may also immediately suspend (block) your access to the Cue Platform and to the Cue Driver Cue Driver Account for the period of investigation, if we suspect an infringement of the Agreement or fraudulent activity from your behalf. The block of access will be removed once the investigation disproves such suspicions. ",
      "We are aiming to provide the highest quality service to all Passengers therefore we are monitoring the activity of Drivers on Cue Platform. If you fail to meet the minimal service requirements, such as the minimal rating and activity score, we are entitled to immediately terminate the Agreement without giving any advance notice.",
      "Additional requirements and safeguards provided in Regulation (EU) 2019/1150 (Regulation) shall apply where the termination of the Agreement or blocking of the access to the Cue Platform affects the rights of the Driver or fleet company using the Cue Services for the provision of Transportation Services in the member state of the European Union or European Economic Area (Member State). ",
      "The Driver and fleet company referred to in section 13.6 (Business User Operating in the Member State) has the right to challenge the termination of the Agreement, blocking, and other alleged non-compliance of Cue with the Regulation, in accordance with the Internal Complaint-Handling System Rules for Business Users of Cue.",
    ],
  },
  {
    title: "AMENDMENTS  ",
    list: [
      {
        firstText:
          "Cue reserves the right to amend these General Terms anytime by uploading the revised version on its website (",
        link: "https://cue.cab/compliance",
        after:
          ")and notifying you (e.g. via e-mail, Cue App or Cue Driver Account) whenever, in the reasonable opinion of Cue, such amendments are material. ",
      },
      {
        text: "Cue shall provide at least 14 days advance notice (e.g. via e-mail, Cue App or Cue Driver Account) about the amendments that affect the rights of Business Users Operating in the Member State, unless: ",
        list: [
          "Cue is subject to a legal or regulatory obligation which requires it to amend the General Terms in a manner which does not allow it to respect the advance notice period;",
          "immediate amendment is required to address an unforeseen and imminent danger related to health, safety or cybersecurity risks, or defending the Cue Services, Passengers or Drivers from fraud, malware, spam or data breaches;",
          "you have elected to waive the advance notice period (e.g. you continue to use Cue Services after receipt of the notice of amendment); or  ",
          "in the reasonable opinion of Cue, amendments are beneficial for the Drivers and do not require technical adjustments from them. ",
        ],
      },
      "If you do not agree to the amendments of the General Terms or other conditions of the Agreement, you have the right to terminate the Agreement by discontinuing the use of the Cue Services and providing termination notice to Cue. The termination of the Agreement takes effect on the effective date of the proposed amendment, unless otherwise provided in your termination notice. Your use of the Cue Services on or after the effective date of the amendment constitutes your consent to be bound by the General Terms or Agreement, as amended. ",
    ],
  },
  {
    title: "APPLICABLE LAW AND COURT JURISDICTION",
    list: [
      "The General Terms and Agreement shall be governed by and construed and enforced in accordance with the laws of Federal Republic of Nigeria. If the respective dispute resulting from General Terms or Agreement could not be settled by negotiations, then the dispute shall be solved in an appropriate Court.",
    ],
  },
  {
    title: "NOTICES",
    list: [
      "You are obliged to immediately notify us of any changes to your contact information",
      {
        text: "Any notice required to be given under the General Terms and Agreement shall be sufficiently given if:  ",
        list: [
          "delivered personally,",
          "sent by courier with proof of delivery,",
          "sent by registered mail,  ",
          "sent by e-mail or",
          "made available via the Cue App or Cue Driver Account",
        ],
      },
      {
        text: " Any notice which is sent or dispatched in accordance with the previous clause shall be deemed to have been received:  ",
        list: [
          "if delivered personally, at the time of delivery to the party; ",
          "if delivered by courier, on the date stated by the courier as being the date on which the envelope containing the notice was delivered to the party; ",
          " if sent by registered mail, on the 10th day after handing the document over to the post office for delivery to the party;   ",
          "if made available via the Cue App or Cue Driver Account, or  ",
          "if sent by e-mail, on the day the party receiving the e-mail confirms receiving the respective e-mail or on the 2nd day following the dispatch of the e-mail provided that the sender has not received an error notice (notifying that the e-mail was not delivered to the party) and has sent the e-mail again on the next calendar day and has not received a similar error notice. ",
        ],
      },
    ],
  },
  {
    title: "FINAL PROVISIONS ",
    list: [
      "If any provision of the General Terms is held to be unenforceable, the parties shall substitute for the affected provision an enforceable provision that approximates the intent and economic effect of the affected provision. ",
    ],
  },
];

export const riderPolicies = [
  {
    title: "Using the Cue app",
    list: [
      "1.1 Cue provides an information society service through Cue app that enables mediation of the requests for transport services between the passengers and drivers and Cue, except where expressly stated, does not provide transport services. Transport services are provided by drivers under a contract (with you) for the carriage of passengers. Drivers provide transport services on an independent basis (either in person or via a company) as economic and professional service providers. Cue is not responsible in any way for the fulfilment of the contract entered into between the passenger (you) and the driver. Disputes arising from consumer rights, legal obligations or from law applicable to the provision of transport services will be resolved between the passengers and drivers. Data regarding the drivers and their transport service is available in the Cue app and receipts for journeys are sent to the email address listed in passenger’s profile.",
      "1.2 . The passenger (you) enters into a contract with the driver for the provision of transport services via the Cue app. Depending on the payment options supported for given location of the journey, you can choose whether to pay the driver for the transport service in cash or use Cue in-app Payment. Payments for Cue Corporate rides are handled by a separate agreement for Business journeys. Charges will be inclusive of applicable taxes where required by law. Charges may include other applicable fees, tolls, and/or surcharges including a booking fee, municipal tolls, airport surcharges or processing fees for split payments. If you wish, you may also choose to pay a Tip to the driver directly or via the use of Cue in-App Payment. We may limit the maximum value of a Tip at our preference.",
      "1.3 During the installation of Cue app, passenger’s mobile number is linked to the respective Cue user account and added to our database. If you are no longer using your mobile number, you must notify Cue within 7 days so we can anonymize your account data. If you do not notify us about any change to your number, your mobile operator may issue the same mobile number to a new person who when using the Cue app then may have access to your data.",
    ],
  },
  {
    title: "2. Promotional Codes ",
    list: [
      {
        text: "2.1 Cue may send you promotional codes on a per promotion basis. Promotional code credit can be applied towards payment on completion of a ride or other features or benefits related to the service and/or a Third Party’s service and are subject any additional terms that are established on a per promotional code basis. Expiration dates of promo codes will be reflected in-app once you have applied the promo code to your account.",
        list: [
          "2.2 If your trip amount exceeds the redeemable credit allocated to your ride, the balance will be automatically deducted from your accounts payment method. Similarly, a promotional code credit only applies on a per ride basis and cannot carry over to a next ride/ trip and therefore will be forfeited. Only one promotional code may be applied per trip.",
          "2.3 Cue reserves the right to cancel any promotional code at any time for any reason. This includes, but is not limited to, if Cue deems that codes are being used in an unlawful or fraudulent manner, those issued mistakenly, and those which have expired.  ",
        ],
      },
    ],
  },
  {
    title: "3. Cue in-App Payment  ",
    list: [
      "3.1 Depending on the payment options supported for the given location of the journey, You can pay for the transport services with a card, mobile carrier billing or other payment methods (e.g: Cue Corporate) as and when available through Cue App. By providing Cue in-App Payment service, Cue acts as commercial agent for the providers of the transport services. Every driver has authorised Cue as their commercial agent for the mediation of conclusion of contracts between the driver and the passenger, including the power to accept payments from the passengers and to forward the payments to the driver. Your obligation to the provider of the transport service will be fulfilled when the payment order is given to transfer funds to DigitPays’ bank account. You, as a passenger are responsible for ensuring that the payment takes place and ensuring that sufficient funds are available.",
      "3.2 You may choose to pay a Tip to the driver using the Cue In-app Payment service. The Tp can be paid via the In-app Payment by means authorised by Cue for that purpose. Cue will not hold a commission for the brokerage of the Tip and the Tip will be transferred to the driver in full amount, excluding any taxes, if applicable. Cue reserves the right to withhold the Tip, if the payment of the Tip is suspected as being fraudulent, illegal, for a purpose other than as a gratuity related to the service provided or used in conflict with DigitPay’s Terms and Conditions .",
      "3.3 When making payments by Cue in-App Payment, Cue receives your payments and forwards money to the driver. Cue may ask additional data from you to verify payment method. .",
      "3.4 When making payments by Cue in-App Payment for transport services, Cue is not responsible for possible third-party payment costs (e.g mobile operators, bank fees). These service providers may charge you additional fees when processing payments in connection with the Cue in-App Payment. Cue is not responsible for any such fees and disclaims all liability in this regard. Your payment method may also be subject to additional terms and conditions imposed by the applicable third-party payment service provider; please review these terms and conditions before using your payment method.",
      "3.5 Cue will be responsible for the functioning of Cue in-App Payment and provide support in resolving problems. The resolution of disputes related to Cue in-App Payment also takes place through us. For payment support service please contact: info@cue.cab. Inquiries submitted by e-mail or Cue App will receive a response within one business day. Cue will resolve Cue in-App Payment related complaints and applications within two business days.",
      "3.6. Upfront Fare. ​You may be offered to use a ride option that allows you to agree to a fixed Fare for a given instance of Transportation service provided by the Driver (i.e Upfront Fare). Upfront Fare is communicated to you via the Cue App before the ride is requested. Upfront Fare shall not be applied if you change the destination during the ride, the ride takes materially longer than estimated due to traffic or other factors, or when other unexpected circumstances impact the characteristics of the ride materially (e.g a route is used where tolls apply).",
    ],
  },
  {
    title: "4. Ordering and cancelling transport services ",
    list: [
      "4.1 If you order a transport service and the driver has agreed to undertake the work then the transport service is considered to be ordered.",
      "4.2 Once a driver confirms that he/she will complete your journey, you will enter into a separate agreement with the driver for the provision of the journey on such terms and conditions as you agree with the driver. Cue does not provide journeys and is not a party to your agreement with the relevant driver..",
      "4.3 Cancelling the use of an ordered transport service is considered to be the situation where the driver has replied to your request and you subsequently reject, cancel or refuse the transport service. When a transport service request is cancelled after certain time period you are required to pay a cancellation fee.",
      "4.4 If you cancel a transport service request on multiple successive instances within 24-hour we may temporarily block your account for warning. After multiple such warnings, we may suspend your account for longer period (e.g 6 months). After that period you could ask to reactivate your account and your application will be reviewed by DigitPay.",
      "4.5 When driver notifies the passenger about the arrival of the vehicle to its destination and passenger or people for whom the transport was ordered do not arrive at the vehicle within certain time period as specified in the Cue app, the request will be deemed cancelled. Sometimes driver may decide to cancel your request, please note that Cue is not responsible for such situations. ",
      "4.6 Once the driver arrives and sends you a notification that he/she has arrived the Cue app may begin charging fare on a waiting time basis according to the rates specified in the Cue app. ",
      "4.7 If you have requested transport services using the Cue app and cause damage to the driver’s vehicle or its furnishing (among else, by blemishing or staining the vehicle or causing the vehicle to stink), the driver will have the right to require you to pay a penalty of 50 EUR and require compensation for any damages exceeding the penalty. If you do not pay the penalty and/or compensate the damage, Cue may pursue the claims on behalf of the provider of the transport service.",
    ],
  },
  {
    title: "5. License to use Cue app ",
    list: [
      "5.1 As long as you comply with these General Terms and Conditions, we agree to grant you a royalty free, revocable, non-exclusive, right to access and use the Cue app in accordance with these General Terms and Conditions, the Privacy Notice and the applicable app-store terms. You may not transfer or sub-license this right to use the Cue app. In the event that your right to use Cue app is cancelled, the corresponding non-exclusive license will also be cancelled.",
    ],
  },
  {
    title: "6. Liability  ",
    list: [
      "6.1 As the Cue app is an information society service (a means of communication) between passengers and drivers, we cannot guarantee or take any responsibility for the quality or the absence of defects in the provision of transport services. As the usage of Cue app for requesting transport services depends on the behaviour of the drivers, Cue does not guarantee that you will always have offers available for the provision of the transport services. ",
      "6.2 The Cue app does not offer or broker transport services for passengers. It is also not an transport agency service for finding passengers for transport providers. The Cue app is used as the means for organising the provision of transport services. ",
      "6.3 The consumer’s right of refund is not applied to Cue app orders. Requesting a refund from the transport service does not withdraw you from the agreement in the course of which the provision of the transport service was ordered. ",
      "6.4 The Cue app is provided on an 'as is' and “as available” basis. Cue does not represent, warrant or guarantee that access to Cue app will be uninterrupted or error free. In case of any faults in the software, we will endeavour to correct them as soon as possible, but please keep in mind that the functioning of the app may be restricted due to occasional technical errors and we are not able to guarantee that the app will function at all times, for example a public emergency may result in a service interruption. ",
      "6.5 DigitPay, its representatives, directors and employees are not liable for any loss or damage that you may incur as a result of using Cue app or relying on, the journey contracted for through the Cue app, including but not limited to: ",
      "6.5.1. any direct or indirect property damage or monetary loss; ",
      "6.5.2. loss of profit; ",
      "6.5.3. loss of business, contracts, contacts, goodwill, reputation and any loss that may arise from interruption of the business; ",
      "6.5.4. loss or inaccuracy of data; and ",
      "6.5.5. any other type of loss or damage. ",
      "6.6 The financial liability of Cue in connection with breach of the contract will be limited to 500 euros. You will have the right to claim for damages only if Cue has deliberately violated the contract. Cue will not be liable for the actions or inactions of the driver and will not be liable for damages that the driver causes to the passengers. ",
      "6.7 You agree to fully indemnify and hold DigitPay, their affiliate companies, representatives, employees and directors harmless from any claims or losses (including liabilities, damages, costs and expenses of any nature) that they suffer as a result of your use of the Cue app (including the journeys you obtain through your use of the Cue app).",
      "6.8 Cue may immediately end your use of the Cue app if you breach these General Terms and Conditions or we consider it necessary to protect the integrity of Cue or the safety of drivers. ",
    ],
  },
  {
    title: "7. Good practice using the Cue app ",
    list: [
      "7.1 As Cue is not a provider or broker of the transport services, any issues with defects or quality of the transport services will be resolved in accordance with the rules and regulations of the transport service provider or the relevant public authority. ",
      "7.2 We ask to fill out a feedback form in the Cue app. This enables us to offer suggestions to the drivers for improving the quality of their service. ",
      "7.3 We expect that you use Cue app in good faith and be respectful of the drivers who offer their services through Cue app. Cue retains the right to close your account if you have violated the terms set out in this General Terms and Conditions or if your activities are malicious, i.e. withholding payment for the provision of the transport service, fraud, being disrespectful towards the drivers, etc. In these cases, your Cue app account may be revoked without prior notice. ",
      "7.4 Cue will make every effort to ensure that only drivers, who have integrity and are respectful of their profession and passengers, use the Cue app. However, we are in no position to guarantee that every provider of transport services, located by the Cue app, satisfies the aforementioned criteria at all times. If you experience objectionable transport service, please notify the company responsible for the service, a supervisory authority or our customer support. ",
    ],
  },
  {
    title: "8. Amendments to the General Terms and Conditions ",
    list: [
      "8.1 If any substantial amendments are made to the General Terms and Conditions, then you will be notified by e-mail or Cue app notifications. If you continue using Cue app, you will be deemed to accept the amendments. ",
    ],
  },
  {
    title: "9. Final Provisions  ",
    list: [
      "The General Terms and Conditions will be governed by and construed and enforced in accordance with the laws of the Federal Republic of Nigeria. If any provision of the General Terms is held to be unenforceable, the parties will substitute for the affected provision an enforceable provision that approximates the intent and economic effect of the affected provision. ",
    ],
  },
];
