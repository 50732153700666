import React from "react";
import Topnav from "../components/Topnav";
import Footer from "../components/Footer";
import {policies, riderPolicies } from "../utils";

const RiderPrivacy = () => {
  return (
    <div className="w-screen text-[12px]" >
      <Topnav />
      <div className=" md:px-[94px] md:py-[75px] py-8 px-12 relative flex flex-col" >
        <div className=" mb-[80px]">
          <div className="text-grey text-md leading-7">
            <p>
              The term “us” or “we” refers to DigitPay, a private limited
              company incorporated and registered under the laws of Federal
              Republic of Nigeria with registration code 1571814, registered
              office 11, Ethiope Close, Abuja, Federal Republic of Nigeria, or
              other DigitPay group company or co-operation partner where
              DigitPay Services are not provided by DIgitPay. A list of DigitPay
              group companies and partners is available
            </p>
            <p>
              In order to use the Cue app you must agree to the terms and
              conditions that are set out below:
            </p>
            <span className="ml-1 text-blue-500">
              <a href=" https://digitpay.net/group" target="_blank">
                https://digitpay.net/group
              </a>
            </span>
          </div>

          <div className="text-grey md:text-[40px] text-2xl leading-[120%] my-10 font-semibold text-center">
            Terms and Conditions for Drivers
          </div>

          {riderPolicies.map((section, sectionIndex) => (
            <div key={sectionIndex} className="my-[30px]">
              <div className="text-grey md:text-[25px] text-xl leading-[120%] mb-5 font-semibold ">{`${
                sectionIndex + 1
              }. ${section.title}`}</div>
              {section.list.map((subsection, subsectionIndex) => (
                <div key={subsectionIndex}>
                  {typeof subsection === "string" ? (
                    <div className="flex gap-2 text-md text-grey leading-7">
                      <div>
                        {sectionIndex + 1}.{subsectionIndex + 1}
                      </div>
                      <div>{subsection} </div>
                    </div>
                  ) : (
                    <div className="flex gap-2">
                      <div>
                        {sectionIndex + 1}.{subsectionIndex + 1}
                      </div>

                      <h2 className="text-md text-grey leading-7">
                        {subsection.firstText}
                        <span className="ml-1 text-blue-500">
                          {" "}
                          <a
                            href={subsection.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {subsection.link}
                          </a>
                        </span>
                        {subsection.after}
                        {subsection.text}
                      </h2>
                    </div>
                  )}
                  {subsection.list && (
                    <ul className="ml-[30px]">
                      {subsection.list.map((item, itemIndex) => (
                        <li
                          key={itemIndex}
                          className="flex gap-2 text-md text-grey leading-7"
                        >
                          {" "}
                          <div>
                            {sectionIndex + 1}.{subsectionIndex + 1}.
                            {itemIndex + 1}
                          </div>
                          <div>{item} </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className="text-grey md:text-[40px] text-2xl leading-[120%] my-5 font-semibold text-center">
          <h3>Terms and Conditions for Passengers/Riders</h3>
        </div>
        <div className="text-grey text-md leading-7">
          <p>
            These General Terms and Conditions set out the terms and conditions
            applying to and governing the usage of the Cue app - technology
            which connects passengers with drivers to help them move around
            cities more efficiently.
          </p>
        </div>
        {policies.map((item, index) => (
          <div className="my-[30px]">
            <div className="text-grey md:text-[25px] text-xl leading-[120%] mb-5 font-semibold ">
              {item.title}
            </div>
            {item.list.map((item, i) => (
              <div className="flex gap-2">
                <div>
                  {index + 1}.{i + 1}
                </div>
                <div>{item} </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default RiderPrivacy;
