import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./Homepage";
import DriverPrivacy from "./DriverPrivacy";
import RiderPrivacy from "./RiderPrivacy";

const Pages = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/driver-privacy" element={<DriverPrivacy />} />
        <Route path="/rider-privacy" element={<RiderPrivacy />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Pages;
