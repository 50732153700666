import React from "react";
import { objectivesItems } from "../utils";
import matrix from "../assets/matrix.svg";
const Objective = () => {
  return (
    <div className="w-screen md:h-[570px] bg-secondary  relative">
      <div className="absolute top-0 left-0 h-full">
        <img src={matrix} alt="" className="w-full h-full object-cover" />{" "}
      </div>
      <div className="w-full 3xl:max-w-[1440px] mx-auto md:p-[94px] h-auto px-2 py-6">
        {" "}
        <div className="font-bold text-[40px] leading-[120%] text-black mb-[60px] text-center">
          Our Objectives
        </div>
        <div className="flex gap-3 flex-col md:flex-row justify-between items-center">
          {objectivesItems.map(item => (
            <div
              key={item.id}
              className="flex flex-col items-center justify-center md:w-[350px]"
            >
              <div className="w-[100px] h-[80px] mb-5">
                <img
                  src={item.img}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="text-2xl text-center text-black font-semibold mb-5">
                {item.name}
              </div>
              <div className="text-center text-base text-black">
                {item.text}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Objective;
