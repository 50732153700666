import React, { useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import { AiOutlineUp } from "react-icons/ai";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import cueLogo from "../assets/Cue.svg";
import { TopnavItems } from "../utils";

const Topnav = () => {
  const [open, setOpen] = useState("");
  const navigate = useNavigate();
  const [openRes, setOpenRes] = useState("");
  const openDropdownHandler = item => {
    if (open === item) {
      setOpen("");
    } else {
      setOpen(item);
    }
  };
  const openResDropdownHandler = item => {
    if (openRes === item) {
      setOpenRes("");
    } else {
      setOpenRes(item);
    }
  };
  return (
    <div className="h-[84px] flex w-full justify-between items-center px-2 md:px-[94px] relative 3xl:max-w-[1440px] ">
      <div className="w-[45%]">
        <img src={cueLogo} alt="logo" onClick={() => navigate("/")} />
      </div>
      <div className="md:flex md:w-[55%] justify-between items-center hidden">
        <div className="flex gap-12 items-center">
          {TopnavItems?.map(item =>
            item.children ? (
              <div className="flex relative">
                <div
                  className="text-black text-base leading-[150%] flex rounded-sm"
                  style={{
                    borderBottom: open === item.open && "3px solid #0195FF",
                  }}
                >
                  {item.name}{" "}
                  <span
                    onClick={() => openDropdownHandler(item.open)}
                    className="mt-1 ml-1 font-extrabold"
                  >
                    {open === item.open ? <AiOutlineDown /> : <AiOutlineUp />}
                  </span>
                </div>

                {open === item.open && (
                  <div className="w-[330px] bg-white flex flex-col p-3 absolute top-[150%] left-0 rounded-[10px] gap-3 z-20">
                    {item?.children?.map(child => (
                      <div
                        key={child.id}
                        className="hover:bg-faintWhite p-3 rounded-[10px]"
                      >
                        <div className="font-semibold text-sm">
                          {child.name}
                        </div>
                        <div className="text-sm">{child.info}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <div key={item.id}>{item.name}</div>
            )
          )}
        </div>
        <div className="flex gap-3 items-center">
          <button className="text-primary border border-primary py-4 px-6 rounded-[5px]">
            Log in
          </button>
          <button className="text-white bg-primary py-4 px-6 rounded-[5px]">
            Get Started
          </button>
        </div>
      </div>
      <div className="z-10 md:hidden block">
        <HiOutlineMenuAlt3
          style={{ fontSize: "36px", color: "#0195FF" }}
          onClick={() => openDropdownHandler("menu")}
        />
      </div>
      {open === "menu" && (
        <div className="absolute h-auto w-[100%] flex flex-col top-[84px] right-0 z-20 p-2  gap-4 bg-white">
          {TopnavItems?.map(item =>
            item.children ? (
              <div className="flex relative">
                <div className="text-black text-base leading-[150%] flex rounded-sm">
                  {item.name}{" "}
                  <span
                    onClick={() => openResDropdownHandler(item.open)}
                    className="mt-1 ml-1 font-extrabold"
                  >
                    {openRes === item.open ? (
                      <AiOutlineDown />
                    ) : (
                      <AiOutlineUp />
                    )}
                  </span>
                </div>

                {openRes === item.open && (
                  <div className="flex flex-col absolute top-[100%] bg-white z-10 w-full">
                    {item?.children?.map(child => (
                      <div
                        key={child.id}
                        className="hover:bg-faintWhite p-3 rounded-[10px]"
                      >
                        <div className="font-semibold text-sm">
                          {child.name}
                        </div>
                        <div className="text-sm">{child.info}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <div key={item.id}>{item.name}</div>
            )
          )}
          <div className="flex gap-3 items-center">
            <button className="text-primary border border-primary py-2 px-3 rounded-[10px]">
              Log in
            </button>
            <button className="text-white bg-primary py-2 px-5 rounded-[10px]">
              Get Started
            </button>
          </div>
        </div>
      )}{" "}
    </div>
  );
};

export default Topnav;
