import React from "react";
import logo from "../assets/Cuecuefooter.svg";
import { footer, footerItems, socials } from "../utils";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="h-[95vh] md:h-[482px] md:px-[94px] px-2 w-full">
      <div className="relative w-full h-full md:py-[75px] py-8 3xl:max-w-[1440px] mx-auto">
        <div className="flex justify-between flex-col md:flex-row items-center md:items-start gap-10 md:gap-0">
          <div className="my-6">
            <img src={logo} alt="" />
          </div>
          <div className="flex gap-10 text-black">
            {footerItems.map(item => (
              <div key={item.id} className="flex flex-col gap-6">
                <div className="font-semibold text-base">{item.name}</div>
                <ul className="flex flex-col gap-4">
                  {item.children.map(child => (
                    <li className="text-sm">{child.name}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className="flex flex-col md:w-[500px] w-full ">
            <div className="font-semibold text-base mb-6">Subscribe</div>
            <div>
              Join our newsletter to stay up to date on features and releases.
            </div>
            <form className="flex md:flex-row flex-col items-center my-4 gap-2">
              <input
                type="text"
                placeholder="Enter your email"
                className="border border-black rounded-[10px] p-3 w-[354px] h-[45px] placeholder:text-grey placeholder:text-sm"
              />
              <button className="rounded-[10px] bg-primary text-white text-base px-6 py-3">
                Subscribe
              </button>
            </form>
            <div className="text-xs">
              By subscribing you agree to with our Privacy Policy and provide
              consent to receive updates from our company.
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row border-t border-primary absolute bottom-0 left-0 md:h-[109px] h-[150px]  w-full md:items-center  justify-between mt-6 p-2">
          <div className="flex gap-6">
            <div className="text-sm text-grey">
              2023 DigitPay. All right reserved.
            </div>
            <ul className="flex gap-6 flex-col md:flex-row">
              {footer.map(item => (
                <li key={item.id} className="text-sm text-grey underline">
                  {" "}
                  <Link to={item.route}>{item.name}</Link>
                </li>
              ))}
            </ul>
          </div>
          <ul className="flex gap-6">
            {socials.map(item => (
              <li key={item.id}>
                <img src={item.img} alt="" />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
